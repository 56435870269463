<template>
  <div>
<!--    <div class="block-title">-->
<!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>新增单位</div>-->
<!--    </div>-->
    <el-form label-position="left" class="common-form left_10" label-width="110px" style="padding-top: 10px"
             :model="form" :rules="rules" ref="form">
      <el-form-item label="单位名称：" prop="name">
        <el-input class="small-input" v-model="form.name" placeholder="请输入单位名称"></el-input>
      </el-form-item>
      <el-form-item label="单位地址：" prop="location">
        <el-select class="small-input" style="width: 100px" placeholder="省" v-model="form.provinceId"
                   @change="changeProvince">
          <el-option
              v-for="item in provinceList"
              :key="item.provinceId"
              :label="item.province"
              :value="item.provinceId">
          </el-option>
        </el-select>
        <span class='left_5 right_5'>省</span>
        <el-select class="small-input" style="width: 100px" placeholder="市" v-model="form.cityId" @change="changeCity">
          <el-option
              v-for="item in cityList"
              :key="item.cityId"
              :label="item.city"
              :value="item.cityId">
          </el-option>
        </el-select>
        <span class='left_5 right_5'>市</span>
        <el-select class="small-input" style="width: 100px" placeholder="区" v-model="form.districtId"
                   @change="changeDistrict">
          <el-option
              v-for="item in districtList"
              :key="item.areaId"
              :label="item.area"
              :value="item.areaId">
          </el-option>
        </el-select>
        <span class='left_5 right_5'>区</span>
        <el-input class="small-input" placeholder="请输入具体地址" v-model="form.locationDetail"></el-input>
      </el-form-item>
      <el-form-item label="运营联系人：" prop="contacts">
        <el-input class="small-input" v-model="form.contacts" placeholder="请输入运营联系人"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="telephone">
        <el-input class="small-input" v-model="form.telephone" placeholder="请输入手机号"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-list">
      <el-button style="padding: 12px 50px" type="primary" :loading="loading" @click="save">保 存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddUnit",
  watch: {
    'form.locationProvince'(val) {
      this.form.location = val + this.form.locationDetail;
    },
    'form.locationCity'(val) {
      this.form.location = this.form.locationProvince + val + this.form.locationDetail;
    },
    'form.locationDistrict'(val) {
      this.form.location = this.form.locationProvince + this.form.locationCity + val + this.form.locationDetail;
    },
    'form.locationDetail'(val) {
      this.form.location = this.form.locationProvince + this.form.locationCity + this.form.locationDistrict + val;
    }
  },
  computed: {
    rules() {
      return {
        name: [
          {required: true, message: '请输入单位名称', trigger: 'blur'},
        ],
        location: [
          {required: true, message: '请设置单位地址'},
          {validator: this.validateLocation}
        ],
        contacts: [
          {required: true, message: '请输入运营联系人', trigger: 'blur'},
        ],
        telephone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ]
      }
    },
  },
  data() {
    return {
      form: {
        name: '',
        locationProvince: '',
        locationCity: '',
        locationDistrict: '',
        locationDetail: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        location: '',
        contacts: '',
        telephone: ''
      },
      isValidateLocation: false,
      provinceList: [],
      cityList: [],
      districtList: [],
      provinceId: '',
      cityId: '',
      districtId: '',
      list: [],
      loading:false
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    validateLocation(rule, value, callback) {
      if (!this.isValidateLocation) callback();
      if (!this.form.provinceId || !this.form.cityId || !this.form.districtId) callback(new Error("请选择单位所在省市区"))
      if (!this.form.locationDetail) callback(new Error("请输入详细地址"));
      callback()
    },
    getProvinceList() {
      this.$axios.get("/sys-api/city-api/provinceList", {}, (res) => {
        if (res.code === '100') {
          this.provinceList = res.data;
        }
      })
    },
    getCityList() {
      this.$axios.get("/sys-api/city-api/cityList", {id: this.form.provinceId}, (res) => {
        if (res.code === '100') {
          this.cityList = res.data;
        }
      })
    },
    getDistrictList() {
      this.$axios.get("/sys-api/city-api/areaList", {id: this.form.cityId}, (res) => {
        if (res.code === '100') {
          this.districtList = res.data;
        }
      })
    },
    changeProvince(provinceId) {
      for (let i = 0; i < this.provinceList.length; i++) {
        if (this.provinceList[i].provinceId === provinceId) {
          this.form.locationProvince = this.provinceList[i].province;
          break;
        }
      }
      this.form.cityId = "";
      this.form.locationCity = "";
      this.form.districtId = "";
      this.form.locationDistrict = "";
      this.getCityList();
    },
    changeCity(cityId) {
      for (let i = 0; i < this.cityList.length; i++) {
        if (this.cityList[i].cityId === cityId) {
          this.form.locationCity = this.cityList[i].city;
          break;
        }
      }
      this.form.districtId = "";
      this.form.locationDistrict = "";
      this.getDistrictList();
    },
    changeDistrict(districtId) {
      for (let i = 0; i < this.districtList.length; i++) {
        if (this.districtList[i].areaId === districtId) {
          this.form.locationDistrict = this.districtList[i].area;
          break;
        }
      }
    },
    save(){
      this.isValidateLocation = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isValidateLocation = false;
          this.loading = true;
          let formData = {
            name:this.form.name,
            locationProvince:this.form.provinceId,
            locationCity:this.form.cityId,
            locationDistrict:this.form.districtId,
            locationDetail:this.form.locationDetail,
            contacts:this.form.contacts,
            telephone:this.form.telephone,
          }
          this.$axios.post("/sys-api/site-api/saveDepartment",formData,(res) => {
            this.loading = false;
            if(res.code === '100'){
              this.$message.success("单位添加成功");
              this.$router.push({
                name:'SiteList'
              })
            }
          })
        }else  this.isValidateLocation = false;
      })
    }
  },
  mounted() {
    this.getProvinceList();
  }
}
</script>

<style scoped lang="scss">
.small-input {
  width: 300px;
}

.btn-list {
  margin-left: 100px;
  margin-top: 20px;
}
</style>
